<template lang="pug">
.top-header-right-block.right-block.is-flex
  .right-block__phone {{$t('layout.header.firstLine.rightBlock.phone')}}
  contact-us-dropdown-component.is-hidden-mobile(@change="$emit('contacts')")
</template>

<script>
export default {
  name: 'TopHeaderRightBlock',

  components: {
    ContactUsDropdownComponent: () =>
      import('./TopHeaderSecondLine/ContactUsDropdown')
  }
}
</script>

<style scoped lang="sass">
.right-block
  align-items: center
  padding-right: 20px
  gap: 10px
  &__phone
    font-family: $lato
    font-size: 14px
    line-height: 20px
    color: #FFFFFF
</style>
